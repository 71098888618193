
.beast-index {
  .heading {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.card-beast {
  padding: 0.5em;

  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(:first-child) {
    margin-top: 0.5em;
  }

  > *:not(:last-child) {
    margin-right: 0.5em;
  }

  .gender {
    padding: 0.5em;
  }

  .beast-text {
    display: flex;
    flex-direction: column;
  }

  .full-name {
    font-weight: bold;
  }

  .evidence-code {
    color: dimgray;
  }
}
